.left-nav {
  position: absolute;

  &__hamburger {
    width: 50px;
    height: 50px;
    font-size: 40px;
    padding-left: 10px;
    color: $color-dark-gray;
    cursor: pointer;
    position: fixed;

    &__bars {
      color: rgb(90, 106, 143);
      height: 25px;
      transition: all 100ms ease-in 0s;

      &__left {
        transform: rotate(180deg);
      }
    }
  }

  &__nav {
    position: fixed;
    top: 53px;
    left: 0;
    height: calc(100vh - 50px);
    min-width: 50px;
    background-color: $mb-left-nav-bg-color;

    &__list {
      list-style: none;
      padding: 5px;
      color: $mb-left-nav-color;
      border-radius: 5px;
      width: 200px;
      transition: all 200ms ease-in 0s;

      &__collapsed {
        width: 50px;
      }

      &__item {
        cursor: pointer;
        margin-bottom: 10px;
        padding: 5px;

        &__expanded {
          padding: 5px;
          padding-right: 50px;
        }

        &__collapsed {
          width: 15px;
          margin: auto;
          margin-bottom: 10px;
        }

        &:hover {
          background-color: $mb-left-nav-bg-hover-color;
        }

        &__icon {
          height: 15px;
          margin-right: 10px;
        }

        &__text {
          font-size: 12px;
          font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
            helvetica neue, Arial, sans-serif, apple color emoji, segoe ui emoji,
            segoe ui symbol;
          line-height: 1.4;
          position: relative;
          top: -2px;
          font-weight: 600;
        }
      }
    }
  }
}
