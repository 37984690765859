.dashboard {
  &__spacer {
    height: 50px;
  }

  &__container {
    margin-left: 250px;
    display: flex;
    flex-direction: row;
    margin-top: 20px;

    &__main_box {
      background-color: $color-white;
      width: 80%;
      box-shadow: 0 -1px 3px 0 rgb(41 48 65 / 20%);
      border-radius: 5px;

      &__header {
        background-color: $mb-header-color;
        color: $color-white;
        margin-top: 0;
        padding: 10px;

        &__title {
          margin: 0;
        }
      }
    }
  }

  &__button_menu {
    margin: 10px;
    margin-left: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;

    &__button {
      background-image: $mb-button-gradient;
      border: 1px solid $color-gray;
      border-radius: 5px;
      padding: 5px;
      text-align: center;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      width: 150px;
      margin: 10px;

      &__image {
        width: 40px;
        height: 40px;
        min-width: 40px;
      }

      &__text {
        font-size: 12px;
        font-weight: 400;
        margin: 0;
      }

      &--active {
        border: $mb-header-color solid 1px;
      }
    }
  }

  &__emailer {
    &__table {
      width: 100%;

      & th {
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        padding: 5px;
      }

      & th:first-child {
        padding-left: 10px;
      }

      & tbody {
        & tr:nth-of-type(odd) {
          background-color: rgba(247, 249, 254, 0.5);
        }

        & tr {
          border-bottom: $mb-seperator-color solid 1px;
          padding-right: 5px;
        }

        & td {
          padding: 5px;
          font-size: 12px;
          font-weight: 400;
          border-top: $mb-seperator-color solid 1px;
          border-right: 0px;
        }

        & td:first-child {
          padding-left: 10px;
        }
      }

      &__option_image {
        width: 20px;
        margin-right: 5px;
        cursor: pointer;
      }

      &__batch_name {
        color: $mb-header-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &__pagination {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 10px;

      &__page {
        background-image: $mb-button-gradient;
        border: 1px solid $color-gray;
        border-radius: 2px;
        padding: 5px;
        text-align: center;
        cursor: pointer;
        margin-right: 5px;
        width: 20px;
        font-size: 12px;
        font-weight: 400;

        &:hover {
          border: $mb-header-color solid 1px;
        }

        &__active {
          background-color: $mb-header-color;
          background-image: none;
          color: $color-white;
        }
      }
    }

    &__body {
      position: relative;
    }

    &__loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);

      & img {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 50px;
        height: 50px;
        transform: translate(-50%, -50%);
        background-color: white;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid $mb-header-color;
      }
    }
  }

  &__right_nav {
    padding: 10px;
    margin-left: 10px;
    max-width: 30%;

    &__option {
      background-image: $mb-rnav-button-gradient;
      border: 1px solid $mb-header-border-color;
      display: inline-block;
      padding: 5px 10px;
      border-radius: 3px;
      cursor: pointer;
      margin-bottom: 20px;

      &:hover {
        background-image: $mb-rnav-button-gradient-hover;
      }

      &__image {
        height: 12px;
      }

      &__text {
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        padding: 5px;
        color: $color-white;
      }

      &:active {
        background-image: $mb-button-active;
      }
    }

    &__info {
      border-top: 1px solid $mb-seperator-color;

      &__text {
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        padding: 5px;
      }
    }
  }
}
