.recipient_stats {
  padding: 10px;
  &__search {
    display: flex;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $mb-seperator-color;

    & input {
      width: 100%;
      margin-right: 20px;
      padding: 5px 10px;
      color: #555;
      background-color: #fefefe;
      border: 1px solid $mb-light-blue;
      border-radius: 3px;
      box-shadow: inset 0 2px 2px rgb(0 0 0 / 4%);

      &:focus-visible {
        border: 1px solid $mb-header-color;
        outline-color: none;
        outline-width: 0px;
      }
    }

    &__button {
      background-image: $mb-rnav-button-gradient;
      border: 1px solid $mb-header-border-color;
      display: inline-block;
      padding: 5px 10px;
      border-radius: 3px;
      cursor: pointer;
      margin-bottom: 20px;
      font-size: 12px;
      font-weight: 400;
      margin: 0;
      color: $color-white;

      &:hover {
        background-image: $mb-rnav-button-gradient-hover;
      }

      &:active {
        background-image: $mb-button-active;
      }
    }
  }

  &__contact {
    cursor: pointer;

    & img {
      width: 25px;
      height: 25px;
    }
  }

  &__name {
    color: $mb-header-color;
    text-decoration: underline;
    cursor: pointer;
  }

  & table tbody tr td {
    text-align: center;
  }

  & table tbody tr td:first-child {
    text-align: left;
  }

  & table tbody tr td:last-child {
    text-align: left;
  }

  &__actions {
    & img {
      width: 25px;
      height: 25px;
      cursor: pointer;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}
