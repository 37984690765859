$orange_main: rgb(238, 143, 54);
$orange_light: rgb(255, 204, 153);
$orange_dark: rgb(204, 102, 0);
$orange_very_light: rgb(254, 244, 234);
$orange_very_dark: rgb(103, 35, 1);

$blue_main: rgb(54, 37, 136);
$blue_light: rgb(151, 186, 255);
$blue_very_light: #f5f7fa;
$blue_dark: rgb(0, 0, 102);

$container-gradient: linear-gradient(
  135deg,
  #f5f7fa 0%,
  #f5f7fa 40%,
  #c3cfe2 100%
);
$vibrant-gradient: linear-gradient(-20deg, #fc6076 0%, #ff9a44 100%);
$mellow-gradient: linear-gradient(to top, #dfe9f3 0%, #fafcff 100%);
$soft-blue-gradient: linear-gradient(135deg, #4479a8, rgb(156, 168, 192));

$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);
$color-gray: rgb(204, 204, 204);
$color-dark-gray: rgb(99, 99, 99);
$light-off-white: rgb(247, 247, 247);
$off-white: rgb(232, 232, 232);

$disabled-red: rgb(208, 59, 59);

$alert-error-background-color: rgb(255, 204, 204);
$alert-error-border: rgb(199, 123, 123) solid 1px;
$alert-error-color: rgb(153, 0, 0);

$alert-info-background-color: rgb(204, 204, 255);
$alert-info-border: rgb(153, 153, 255) solid 1px;
$alert-info-color: rgb(0, 0, 102);

$alert-warning-background-color: rgb(255, 255, 204);
$alert-warning-border: rgb(255, 255, 153) solid 1px;
$alert-warning-color: rgb(102, 102, 0);

$alert-success-background-color: rgb(204, 239, 204);
$alert-success-border: rgb(145, 181, 145) solid 1px;
$alert-success-color: rgb(0, 102, 0);

$box-shadow: 0px 0px 3px $blue_light;

$left-nav-width: 15rem;
$header-height: 5rem;
$menu-font-size: 1.2rem;

$mb-left-nav-color: #8fa9d1;
$mb-left-nav-bg-color: #293041;
$mb-left-nav-bg-hover-color: #3e4a5c;
$mb-avatar-gradient: linear-gradient(to bottom, #859bbb, #5b6c86);
$mb-logout-color: #fc5255;
$mb-header-color: #4b8ffa;
$mb-header-border-color: #3479eb;
$mb-button-gradient: linear-gradient(to bottom, #f9f9f9, #fff 99%);
$mb-seperator-color: #cfdbeb;
$mb-rnav-button-gradient: linear-gradient(to bottom, #5f9bff, #478cfe, #3479eb);
$mb-rnav-button-gradient-hover: linear-gradient(to bottom, #5f9bff, #478cfe);
$mb-light-blue: #c4daf3;
$mb-button-active: linear-gradient(to bottom, #478cfe, #5f9bff, #478cfe);
