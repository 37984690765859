.email_popup {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }

  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 80vh;
    width: 70%;
    height: 50%;
    background-color: $color-white;
    z-index: 10001;
    overflow-y: auto;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 3px 3px 8px darkgray;

    &__header {
      background-color: $mb-header-color;
      margin: 0;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 50px;

      &__title {
        margin: 0;
        color: $color-white;
        padding: 10px;
      }
    }

    &__body {
      margin-top: 50px;
      padding: 10px;

      &__menu {
        text-align: right;
        margin-top: 10px;
        margin-bottom: 10px;

        &__page_nav {
          &__arrow {
            cursor: pointer;
            margin-right: 5px;
            margin-left: 5px;
            font-size: 20px;
            font-weight: 400;
            color: $mb-header-color;
          }
        }

        &__action {
          margin-top: 10px;
          &__item {
            margin-right: 5px;
            padding: 5px;
            border: 1px solid black;
            border-radius: 3px;
            display: block;
            text-align: center;
            right: 0;
            width: 100px;
            transition: 0.2s;
            margin-left: calc(100% - 110px);
            margin-top: 20px;
            &__resolved {
              background-color: green;
              color: $color-white;
            }

            &__sent {
              background-color: blue;
              color: $color-white;
            }

            &__ignored {
              background-color: red;
              color: $color-white;
            }

            &__sendable {
              background-color: rgb(248, 218, 84);
              color: $color-black;
              cursor: pointer;

              &:hover {
                transform: scale(1.02);
              }
              &:active {
                transform: scale(0.98);
              }
            }
          }
        }
      }

      &__recipient_email {
        font-size: 12px;
        padding-bottom: 10px;
        border-bottom: 1px solid $mb-seperator-color;
        margin-bottom: 20px;
      }

      &__actions {
        margin-bottom: 20px;
        float: left;

        img {
          width: 30px;
          height: 30px;
          cursor: pointer;

          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

.recipient_popup {
  &__status {
    text-align: right;

    & img {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }
}
