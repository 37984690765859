body {
  font-family: "Montserrat", sans-serif;
  background-image: $container-gradient;
  box-sizing: border-box;
  min-height: 100vh;
}

.App {
  &__loaded {
    padding-left: $left-nav-width;
  }
}

::selection {
  background-color: $orange_main;
  color: $color-white;
}
