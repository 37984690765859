.login {
  background-image: url("../../images/backgrounds/bg.jpg");
  background-size: cover;
  height: 100vh;
  position: relative;
  background-position: center;

  &__header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: $color-white;
    border-bottom: 3px solid $orange_main;
    text-align: right;

    &__logo {
      display: inline-block;
      height: 50px;
      width: 300px;
      background-image: url("../../images/logo/logo-full.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 20px;
    }

    &__avatar {
      background-image: $mb-avatar-gradient;
      width: 35px;
      height: 35px;
      box-sizing: border-box;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      padding-top: (25px - 16px);
      position: relative;
      margin-bottom: 7px;
      margin-right: 10px;
      cursor: pointer;

      &__user {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $color-white;
        font-weight: 700;

        &__menu {
          position: absolute;
          top: 160%;
          right: 0;
          width: 200px;
          background-color: white;
          box-shadow: 0px 0px 3px $color-gray;

          &__item {
            text-align: left;
            padding: 10px;

            &__icon {
              height: 15px;
              margin-right: 10px;
            }

            &__text {
              color: $mb-logout-color;
              font-size: 12px;
              position: relative;
              top: -4px;
            }
          }
        }
      }
    }
  }

  &__window {
    position: absolute;
    right: 100px;
    top: 150px;
    width: 400px;
    height: 400px;
    background-color: $color-white;
    border-radius: 10px;
    box-shadow: 0px 0px 3px $blue_light;
    padding: 20px;

    &__logo {
      height: 50px;
      background-image: url("../../images/logo/logo-full.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      padding-bottom: 50px;
      margin-bottom: 50px;
      border-bottom: 1px solid $color-gray;
    }
    &__form {
      &__label {
        text-transform: uppercase;
        font-weight: 700;
        display: block;
        margin-bottom: 10px;
      }

      &__input {
        width: 90%;
        padding: 5px;
        margin-bottom: 20px;
      }

      &__button {
        background-color: $orange_main;
        padding: 10px 20px;
        border-radius: 5px;
        text-transform: uppercase;
        color: $color-white;
        display: block;
        margin: auto;
        text-align: center;
        width: 10rem;
        border: 1px solid $orange_dark;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.02);
        }

        &:active {
          transform: scale(0.98);
        }
      }
    }
  }
}
