.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  &__gif {
    width: 150px;
    height: 150px;
    padding: 10px;
    background-color: $color-white;
    border-radius: 5px;
    border: 1px solid $blue-dark;
    margin: auto;
    margin-bottom: 30px;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  &__text {
    font-size: 1.5rem;
    font-weight: 600;
  }
}
